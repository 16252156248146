<template>
  <ArchiveWrapper :title="title" :sections="sections" :card-actions="cardActions" type="videos" />
</template>

<script>
import { computed } from 'vue'
import { useHead } from '@vueuse/head'

import { useTenant } from '@/core'
import useArchive from '@/composables/useArchive'
import ArchiveWrapper from './common/ArchiveWrapper'
import useTranslations from '@/composables/useTranslations'

export default {
  name: 'Media',

  components: {
    ArchiveWrapper
  },

  setup() {
    const { tenant } = useTenant()
    const { getTenantTranslation } = useTranslations()
    const { title, sections } = useArchive()
    const cardActions = {
      type: 'media',
      action: child => {
        return `/media/${child.slugPath.split('/').slice(1).join('/')}`
      }
    }

    useHead({
      title: computed(() => `${title.value} - ${getTenantTranslation(tenant.value.name)}`)
    })

    return {
      title,
      sections,
      cardActions
    }
  }
}
</script>
